
import { FBFormModel, FBFormAssignmentModel } from './../../../../models/formBuilderModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../shared/services/alert.service';
import { downloadFile, getShortDateFormatted } from '../../../../helpers/utils';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deleteFBForm, getAvailableForms, getFBFormTable, loadFormDoc, loadFormDocSuccess, toggleFormAssignmentArchived, toggleFormAssignmentArchivedSuccess } from 'src/app/core/store/form-builder/form-builder.actions';

@Component({
  selector: 'app-forms-available',
  templateUrl: './forms-available.component.html',
  styleUrls: ['./forms-available.component.scss']
})
export class FormsAvailableComponent extends BaseComponent implements OnInit {

  formsList: FBFormAssignmentModel[];
  canEdit: boolean;
  loadingAvailableForms = false;
  filter: any;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  includeArchived?: boolean = false;
  formNameFilter;
  loadingDoc;
  loadingDocAssignmentId;

  managedUserId;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService
  ) {
    super();
  }

  ngOnInit() {
    if (this.route.snapshot.parent.params.userid) {
      this.managedUserId = this.route.snapshot.parent.params.userid;
    }
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 9001);
      }),
      this.store.select(s => s.formBuilderState.availableForms).subscribe(availableForms => {
        if (availableForms) {
          this.formsList = availableForms;
        }
      }),

      this.store.select(s => s.formBuilderState.loadingAvailableForms).subscribe(loadingAvailableForms => {
        this.loadingAvailableForms = loadingAvailableForms;
      }),

      this.store.select(s => s.formBuilderState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.actions$.pipe(
        ofType(loadFormDocSuccess),
        tap(action => {
          downloadFile(action.doc, 'FormSubmission.pdf');
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(toggleFormAssignmentArchivedSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
    );
    this.reloadList();
  }  

  reloadList() {
    this.store.dispatch(getAvailableForms({ userID: this.managedUserId, includeArchived: this.includeArchived }));
  }

  toggleArchivedStatus(form: FBFormAssignmentModel) {
    if (form.id > 0) {
      this.store.dispatch(toggleFormAssignmentArchived({ assignmentId: form.id }));
    }
  }

  canArchive(form: FBFormAssignmentModel) {
    if (form.archived) {
      return false;
    }

    if (!form.signatureRequired) {
      return true;
    }

    return this.hasSignedSubmission(form);
  }

  fillForm(form: FBFormAssignmentModel) {
    if (form.formSubmission) {
      this.router.navigate(['../../form-filling/fill/' + form.form.id + '/' + form.formSubmission.id], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../form-filling/fill/' + form.form.id], { relativeTo: this.route });
    }
  }

  fillHRForm(form: FBFormAssignmentModel) {
    const HRQueryParams = { editingAsHR: true };
    if (form.formSubmission) {
      this.router.navigate(['../../form-filling/fill/' + form.form.id + '/' + form.formSubmission.id], { relativeTo: this.route, queryParams: HRQueryParams });
    } else {
      this.router.navigate(['../../form-filling/fill/' + form.form.id], { relativeTo: this.route, queryParams: HRQueryParams });
    }
  }

  signForm(form: FBFormAssignmentModel) {
    if (form.formSubmission) {
      this.router.navigate(['../../form-filling/sign/' + form.formSubmission.id], { relativeTo: this.route });
    }
  }
  hasSignedSubmission(form: FBFormAssignmentModel) {
    if (form.formSubmission) {
      return form.formSubmission.signedDate;
    }
    return false;
  }

  hasSignedSubmissionHR(form: FBFormAssignmentModel) {
    if (form.formSubmission) {
      return form.formSubmission.signedByHRDate;
    }
    return false;
  }

  getLastDate(form: FBFormAssignmentModel) {
    if (form.formSubmission) {
      return getShortDateFormatted(new Date(form.formSubmission.updatedDate));
    }
    return '-';
  }

  downloadForm(formAssignment: FBFormAssignmentModel) {
    this.loadingDocAssignmentId = formAssignment.id;
    this.store.dispatch(loadFormDoc({ formSubmissionID: formAssignment.formSubmissionID }));
  }

  seeFormResult(formAssignment: FBFormAssignmentModel) {
    this.router.navigate(['../../form-filling/form-submission/' + formAssignment.formID + '/' + formAssignment.formSubmissionID], { queryParams: { backLink: '../../list' }, relativeTo: this.route });
  }
}

