import { FBFormModel, FBDocFileModel, FBFormTypeModel, FBFormFieldTypeModel, FBFormAssignmentModel, FBFormSubmissionModel, FBFormRoleAssignmentModel, InquirySimpleModel, FBMappingColumnModel, FBFormFieldModel, UsersWithFormsModel, SimpleFormsListModel, FBFormJobTitleAssignmentModel } from './../../models/formBuilderModels';
import { ProspectiveCandidateReferenceModel } from 'src/app/models/prospectiveCandidateModel';
import { ProspectiveCandidateModel } from './../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class FormBuilderService {

  constructor(private http: HttpClient) {
  }

  getFormsDatatable(filters: any): Observable<PagingResultsModel<FBFormModel>> {
    return this.http.post<PagingResultsModel<FBFormModel>>
      (`${environment.apiServer.webApiURL}/FormBuilder/FormsDatatable`, filters);
  }

  saveForm(form: FBFormModel) {
    return this.http.post<FBFormModel>(`${environment.apiServer.webApiURL}/FormBuilder/Form`,
      form);
  }


  uploadFormfile(formID: number, file: File) {
    const formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', file);
    formData.append('files[0].FormID', formID.toString());


    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<FBDocFileModel>(`${environment.apiServer.webApiURL}/FormBuilder/Form/UploadFile`, formData, options);
  }

  deleteForm(formID: number) {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/FormBuilder/Form/${formID}`);
  }

  
  duplicateFBForm(formID: number) {
    return this.http.get<FBFormModel>(`${environment.apiServer.webApiURL}/FormBuilder/DuplicateForm/${formID}`);
  }
  

  getForm(formID: number) {
    return this.http.get<FBFormModel>(`${environment.apiServer.webApiURL}/FormBuilder/Form/${formID}`);
  }

  getFormTypes() {
    return this.http.get<FBFormTypeModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/FormTypes`);
  }

  getFormFieldTypes() {
    return this.http.get<FBFormFieldTypeModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/FormFieldTypes`);
  }
  getMappingColumns() {
    return this.http.get<FBMappingColumnModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/MappingColumns`);
  }

  getAvailableForms(userID?: number, includeArchived?: boolean) {
    var queryString = includeArchived != null ? "?includeArchived=" + includeArchived : ""
    if (userID) {
      return this.http.get<FBFormAssignmentModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/AvailableForms/${userID}${queryString}`);
    } else {
      return this.http.get<FBFormAssignmentModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/AvailableForms/${queryString}`);
    }
  }
  saveFormSubmissionForm(formSubmission: FBFormSubmissionModel) {
    return this.http.post<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission`,
      formSubmission);
  }
    toggleFormAssignmentArchived(assignmentId: number): Observable<any> {
        return this.http.post<any>(`${environment.apiServer.webApiURL}/FormBuilder/ToggleFormAssignmentArchived/${assignmentId}/`, { assignmentId: assignmentId });
    }
  getFormSubmission(formID: number, formSubmissionID?: number, jobApplicationID?: number, prospectiveCandidateID?: number) {
    if (prospectiveCandidateID && jobApplicationID && !formSubmissionID){
      return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/0/${jobApplicationID}/${prospectiveCandidateID}`);
    }
    else if (prospectiveCandidateID && jobApplicationID && formSubmissionID > 0){
      return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/${formSubmissionID}/${jobApplicationID}/${prospectiveCandidateID}`);
    }
    else if (jobApplicationID && !formSubmissionID) {
      return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/0/${jobApplicationID}`);
    } 
    else {
      if (formSubmissionID > 0)
        return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/${formSubmissionID}`);
      else
        return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}`);
    }
  }

  uploadValueFieldFile(formSubmissionID: number, formFieldID: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name)

    //const options = { headers: new HttpHeaders() };
    //options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<number>(`${environment.apiServer.webApiURL}/FormBuilder/FormAssignment/AttachFile/${formSubmissionID}/${formFieldID}`, formData);
  }
  finishFormSubmissionForm(formSubmission: FBFormSubmissionModel) {
    return this.http.post<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/FormBuilder/FinishFormSubmission`,
      formSubmission);
  }


  getFormSubmissionDocToSign(formSubmissionID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmissionDocumentToSign/${formSubmissionID}`,
      { responseType: 'arraybuffer' as 'json' });
  }


  signFormSubmissionDoc(formSubmissionID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmissionSignDoc/${formSubmissionID}`,
      signedFields,
      { responseType: 'arraybuffer' as 'json' });
  }

  getFormsAssignmentDatatable(filters: any): Observable<PagingResultsModel<FBFormAssignmentModel>> {
    return this.http.post<PagingResultsModel<FBFormAssignmentModel>>
      (`${environment.apiServer.webApiURL}/FormBuilder/FormsAssignmentDatatable`, filters);
  }

  getUsersWithFormAssignmentTable(filters: any): Observable<PagingResultsModel<UsersWithFormsModel>> {
    return this.http.post<PagingResultsModel<UsersWithFormsModel>>
      (`${environment.apiServer.webApiURL}/FormBuilder/GetUsersWithFormsDataTable`, filters);
  }
  
  addFormAssignment(formAssignment: FBFormAssignmentModel) {
    return this.http.post<FBFormAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormAssignment`,
      formAssignment);
  }
  setFormAssignmentCanOptout(formAssignmentID: number, canOptOut: boolean) {
    return this.http.get<FBFormAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/SetFormAssignmentCanOptout/${canOptOut}/${formAssignmentID}`);
  }

  fillCAQHForm() {
    return this.http.get<FBFormAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormAssignment/CAQH`);
  }

  getAllForms() {
    return this.http.get<FBFormModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/FormsList`);
  }

  getFormsFilter() {
    return this.http.get<SimpleFormsListModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/FormsFilterList`);
  }
  getAllUsersWithForms(tab: string) {
    return this.http.get<UsersWithFormsModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/UsersWithFormsList/${tab}`);
  }
  deleteFBFormAssignment(formAssignmentID: number) {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/FormBuilder/FormAssignment/${formAssignmentID}`);
  }

  loadFormDoc(formSubmissionID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/FormBuilder/FormSubmissionGetDoc/${formSubmissionID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  updateFormsRolesAssignment(formRoleAssignmentModel: FBFormRoleAssignmentModel) {
    return this.http.post<FBFormRoleAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormRoleAssignment`,
      formRoleAssignmentModel);
  }

  getFormsRolesAssignment(formID: number) {
    return this.http.get<FBFormRoleAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormRoleAssignment/${formID}`);
  }

  updateFormsJobTitlesAssignment(formJobTitleAssignmentModel: FBFormJobTitleAssignmentModel) {
    return this.http.post<FBFormJobTitleAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormJobTitleAssignment`,
      formJobTitleAssignmentModel);
  }

  getFormsJobTitlesAssignment(formID: number) {
    return this.http.get<FBFormJobTitleAssignmentModel>(`${environment.apiServer.webApiURL}/FormBuilder/FormJobTitleAssignment/${formID}`);
  }

  getInquiryList() {
    return this.http.get<InquirySimpleModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/InquiryList`);
  }

  getSectionFields(formFieldId: number) {
    return this.http.get<FBFormFieldModel[]>(`${environment.apiServer.webApiURL}/FormBuilder/SectionFields/${formFieldId}`);
  }

}
