import { createAction, props } from '@ngrx/store';
import { CompletedKraModel, EmployeeReviewInfoModel, EmployeeReviewModel, EmployeeReviewOpenPeriod, EmployeeReviewOversightLoadModel, KraReportModel, OverseenEmployeeModel, SecondOversightReviewViewModel } from '../../../models/employeeReviewModels';
import { EmployeeReviewTemplateModel } from '../../../models/employeeReviewTemplateModels';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { ModifiedSelectItem } from '../../../models/utilModels';

export const loadMyTemplates = createAction('[Employee Review] Load My Templates');
export const loadMyTemplatesSuccess = createAction('[Employee Review] Load My Templates Success',
  props<{ templates: EmployeeReviewTemplateModel[] }>());

export const postPlayerReview = createAction('[Employee Review] Post Player Review', props<{ review: EmployeeReviewModel }>());
export const postPlayerReviewSuccess = createAction('[Employee Review] Post Player Review Success');
export const postPlayerReviewFailure = createAction('[Employee Review] Post Player Review Failure');

export const postOversightReview = createAction('[Employee Review] Post Oversight Review', props<{ review: EmployeeReviewModel }>());
export const postOversightReviewSuccess = createAction('[Employee Review] Post Oversight Review Success');
export const postOversightReviewFailure = createAction('[Employee Review] Post Oversight Review Failure');

export const autoSaveOversightReview = createAction('[Employee Review] AutoSave Oversight Review', props<{ review: EmployeeReviewModel }>());
export const autoSaveOversightReviewSuccess = createAction('[Employee Review] AutoSave Oversight Review Success');
export const autoSaveOversightReviewFailure = createAction('[Employee Review] AutoSave Oversight Review Failure');

export const getEmployeeReviews = createAction('[Employee Review] Get Employee Reviews', props<{ userId: number }>());
export const getEmployeeReviewsSuccess = createAction('[Employee Review] Get Employee Reviews Success', props < { oversightData: EmployeeReviewOversightLoadModel }>());
export const getEmployeeReviewsFailure = createAction('[Employee Review] Get Employee Reviews Failure');

export const save2ndOversightReview = createAction('[Employee Review] Save 2nd Oversight Review', props<{ secondOversightReview: SecondOversightReviewViewModel }>());
export const save2ndOversightReviewSuccess = createAction('[Employee Review] Save 2nd Oversight Review Success');


export const loadReviews = createAction('[Employee Review] Load Reviews',
  props<{ userId:number, filter: any }>());
export const loadReviewsSuccess = createAction('[Employee Review] Load Reviews Success',
  props<{ employeeReviews: PagingResultsModel<EmployeeReviewInfoModel> }>());


export const getOverseenEmployee = createAction('[Employee Review] Get Overseen Employee', props<{ userId: number }>());
export const getOverseenEmployeeSuccess = createAction('[Employee Review] Get Overseen Employee Success',
  props<{ user: OverseenEmployeeModel }>());

export const getEmployeeReview = createAction('[Employee Review] Get Employee Review', props<{ reviewId: number }>());
export const getEmployeeReviewSuccess = createAction('[Employee Review] Get Employee Review Success',
  props<{ review: EmployeeReviewOversightLoadModel }>());


export const getOversightReview = createAction('[Employee Review] Get Oversight Review', props<{ reviewId: number }>());
export const getOversightReviewSuccess = createAction('[Employee Review] Get Oversight Review Success',
  props<{ review: EmployeeReviewOversightLoadModel }>());


export const autoSavePlayerReview = createAction('[Employee Review] AutoSave Player Review', props<{ review: EmployeeReviewModel }>());
export const autoSavePlayerReviewSuccess = createAction('[Employee Review] AutoSave Player Review Success');
export const autoSavePlayerReviewFailure = createAction('[Employee Review] AutoSave Player Review Failure');

export const getCompletedKraList = createAction('[Employee Review] Get Completed KRA List', props<{ filter: any }>());
export const getCompletedKraListSuccess = createAction('[Employee Review] Get Completed KRA List Success',
  props<{ completedKraList: PagingResultsModel<CompletedKraModel> }>());

export const saveMeetsExpectations = createAction('[Employee Review] Save Meets Expectations', props<{ review: CompletedKraModel }>());
export const saveMeetsExpectationsSuccess = createAction('[Employee Review] Save Meets Expectations Success');

export const getReviewOpenPeriod = createAction('[Employee Review] Get Review Open Period', props<{ userId: number }>());
export const getReviewOpenPeriodSuccess = createAction('[Employee Review] Get Review Open Period Success',
  props<{ openPeriod: EmployeeReviewOpenPeriod }>());

export const getKraList = createAction('[Employee Review] Get KRA List', props<{ filter: any }>());
export const getKraListSuccess = createAction('[Employee Review] Get KRA List Success',
  props<{ kraList: PagingResultsModel<KraReportModel> }>());

export const loadTemplateFilter = createAction('[Employee Review] Load Template Filter');
export const loadTemplateFilterSuccess = createAction('[Employee Review] Load Template Filter Success',
  props<{ templates: ModifiedSelectItem[] }>());
