<form class="card mt-2" *ngIf="canEdit">
  <div class="card-body">
    <h2 class="mb-3">Employee Benefit Enrollment Information</h2>

    <h5>Filter</h5>
    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Employee name:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" [(ngModel)]="employeeNameFilter" class="form-control" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Enrolled in plan:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="plansOptions" [(ngModel)]="enrolledPlanNameFilter" placeholder="Select ..." (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

      </div>

    </div>

    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Enrolled plan carrier:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="planCarriersOptions" [(ngModel)]="enrolledPlanCarrierFilter" placeholder="Select ..." (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

      </div>

    </div>

    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Declined plan type:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="planTypesOptions" [(ngModel)]="declinedPlanNameFilter" placeholder="Select ..." (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

      </div>

    </div>

    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Complete:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="completeFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

      </div>

    </div>
    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Signed:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="signedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

      </div>

    </div>

    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Year:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yearEnrollmentOptions" [(ngModel)]="yearFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

      </div>

    </div>

    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Benefit Class:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [multiple]="true" [options]="classOptions" [(ngModel)]="classesFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

      </div>

    </div>


    <i *ngIf="loadingBenefitsEmployeesList || loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" *ngIf="!loadingExcel" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [loading]="loadingBenefitsEmployeesList" [value]="benefitsEmployeesList?.result" [responsive]="true" [first]="this.filter.first"
             [paginator]="true" [rowsPerPageOptions]="[20,100,200,500,1000]" [(rows)]="rowsAmount" [totalRecords]="benefitsEmployeesList?.totalRecords" [lazy]="true"
             (onLazyLoad)="loadPage($event)">
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="false" width="120px"></th>
          <th [pSortableColumn]="'employee.fullname'">
            Full Name
            <p-sortIcon [field]="'employee.fullname'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'employee.birthdate'">
            DOB
            <p-sortIcon [field]="'employee.birthdate'"></p-sortIcon>
          </th>
          <th>
            SSN
          </th>
          <th [pSortableColumn]="'employee.trainingStartDate'">
            Hire Date
            <p-sortIcon [field]="'employee.trainingStartDate'"></p-sortIcon>
          </th>
          <th>
            Benefit Class
          </th>
          <th>
            Dependents
          </th>
          <th>
            Cost per period
          </th>
          <th>
            Date of Enrollment
          </th>
          <th>
            Enrollment Period
          </th>
          <th>
            Enrollment Complete
          </th>
          <th>
            Enrollment Set Signed
          </th>
          <th>
            Enrolled Plans
          </th>
          <th>
            Declined Plans
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-employeeWithBenefits>
        <tr>
          <td *ngIf="false">
          </td>

          <td>
            <span class="ui-column-title">Full Name</span>
            {{employeeWithBenefits.user.firstName }} {{employeeWithBenefits.user.lastName }}
          </td>

          <td>
            <span class="ui-column-title">DOB</span>
            {{employeeWithBenefits.employee.birthdate | date:'shortDate'}}
          </td>

          <td>
            <span class="ui-column-title">SSN</span>
            
            <ng-container *ngIf="employeeWithBenefits.employee.ssn =='XX-XXX-XXXX'">
              #{{employeeWithBenefits.user.id }}
            </ng-container>
            <ng-container *ngIf="employeeWithBenefits.employee.ssn !='XX-XXX-XXXX'">
              {{employeeWithBenefits.employee.ssn }}
            </ng-container>


          </td>
          <td>
            <span class="ui-column-title">Hire Date</span>
            {{employeeWithBenefits.employee.trainingStartDate | date:'shortDate' }}
          </td>

          <td>
            <span class="ui-column-title">Benefit Class</span>
            {{getClassLabel(employeeWithBenefits.employee.benefitClassId)}}
          </td>
          <td>
            <span class="ui-column-title">Dependents</span>
            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div *ngFor="let dependent of getAllDependents(employeeWithBenefits.enrollmentSet)">
                {{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})
                <div *ngIf="canEdit" class="pointer ml-2 d-inline " (click)="editDependent(dependent)">
                  <i title="Edit Dependent" class="fa-xs fas fa-edit"></i>
                </div>

              </div>

            </ng-container>
          </td>

          <td>
            <span class="ui-column-title">Total Cost per Period</span>
            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div>Cost for employee: $ {{getTotalCost(employeeWithBenefits.enrollmentSet).toFixed(2)}}</div>
              <div>Cost payed by employer: $ {{getTotalEmployerCost(employeeWithBenefits.enrollmentSet).toFixed(2)}}</div>
            </ng-container>
          </td>

          <td>
            <span class="ui-column-title">Enrollment</span>
            <div>{{getEnrollmentLifeChangingEvent(employeeWithBenefits.enrollmentSet)}} </div>
            <div *ngIf="employeeWithBenefits.enrollmentSet && employeeWithBenefits.enrollmentSet.activatedDate">Activated: {{employeeWithBenefits.enrollmentSet?.activatedDate | date:'shortDate' }}</div>
          </td>

          <td>
            <span class="ui-column-title">Enrollment Period</span>
            {{getEnrollmentDates(employeeWithBenefits.enrollmentSet)}}
          </td>
          <td class="text-center">
            <span class="ui-column-title">Enrollment Complete</span>

            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div *ngIf="!employeeWithBenefits.benefitsEnrollmentPending">
                <i class="fas fa-check-circle text-success" title="Completed"></i>
              </div>
              <div *ngIf="employeeWithBenefits.benefitsEnrollmentPending">
                <i class="fas fa-minus-circle text-danger" title="Pending"></i>
              </div>
            </ng-container>

            <ng-container *ngIf="!employeeWithBenefits.enrollmentSet">
              <div>
                <i class="fas fa-minus-circle text-gray" title="Not Started"></i>
              </div>
            </ng-container>
          </td>
          <td class="text-center">
            <span class="ui-column-title">Enrollment Set Signed</span>

            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div *ngIf="employeeWithBenefits.enrollmentSet.signedDate" class="d-inline">
                <i class="fas fa-check-circle text-success" title="Completed"></i>
                <span class="pointer ml-2" *ngIf="canEdit" (click)="downloadSignDocumentZipFile(employeeWithBenefits)"><i title="Download signed forms" class="fas fa-file-archive"></i></span>
              </div>
              <div *ngIf="!employeeWithBenefits.enrollmentSet.signedDate">
                <i class="fas fa-minus-circle text-danger" title="Pending"></i>
              </div>
              <div class="pointer ml-2 d-inline" *ngIf="canEdit && employeeWithBenefits.enrollmentSet.enrollments && employeeWithBenefits.enrollmentSet.enrollments.length" (click)="editEnrollmentSet(employeeWithBenefits.enrollmentSet)">
                <i title="Edit Enrollment Plans" class="fa-xs fas fa-edit"></i>
              </div>

            </ng-container>
          </td>


          <td>
            <span class="ui-column-title">Enrolled Plans</span>



            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div *ngFor="let enrollment of employeeWithBenefits.enrollmentSet.enrollments">
                <span>
                  {{enrollment.benefitPlan.benefitPlan.planName}}
                </span>
                <br>
              </div>
            </ng-container>
          </td>

          <td>
            <span class="ui-column-title">Declined Plans</span>
            <ng-container *ngIf="employeeWithBenefits.enrollmentSet">
              <div *ngFor="let declined of employeeWithBenefits.enrollmentSet.declineds">
                <span>{{declined.benefitPlanType.planTypeName}}</span><br>
              </div>
            </ng-container>
          </td>
        </tr>

      </ng-template>
    </p-table>
  </div>
</form>

<p-confirmDialog></p-confirmDialog>




<p-dialog [header]="'Edit Benefits Dependent'" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="benefitsDependentEdit">

  <form class="" [formGroup]="benefitsDependentForm" *ngIf="benefitsDependentForm && benefitsDependentEdit">
    <div class="row m-0">
      <div class="col-4 d-flex align-items-center">
        <b>Legal First Name</b>
      </div>
      <div class="col-8 ">
        <input id="firstNameInput" type="text" class="form-control mt-2" formControlName="firstName" />
        <app-validation-message [form]="benefitsDependentForm" [path]="'firstName'" [name]="'firstName'" [label]="'First Name'"> </app-validation-message>
      </div>

      <div class="col-4 d-flex align-items-center">
        <b>Legal Middle Name</b>
      </div>
      <div class="col-8 ">
        <input id="middleNameInput" type="text" class="form-control mt-2" formControlName="middleName" />
        <app-validation-message [form]="benefitsDependentForm" [path]="'middleName'" [name]="'middleName'" [label]="'Middle Name'"> </app-validation-message>
      </div>


      <div class="col-4 d-flex align-items-center">
        <b>Legal Last Name</b>
      </div>
      <div class="col-8 ">
        <input id="lastNameInput" type="text" class="form-control mt-2" formControlName="lastName" />
        <app-validation-message [form]="benefitsDependentForm" [path]="'lastName'" [name]="'lastName'" [label]="'Last Name'"> </app-validation-message>
      </div>


      <div class="col-4 d-flex align-items-center">
        <b>Suffix</b>
      </div>
      <div class="col-8 ">
        <p-dropdown styleClass="form-control mt-2 w-100" [options]="suffixOptions" placeholder="Select type..." formControlName="suffixName"></p-dropdown>
        <app-validation-message [form]="benefitsDependentForm" [path]="'suffixName'" [name]="'suffixName'" [label]="'Suffix'"> </app-validation-message>
      </div>


      <div class="col-4 d-flex align-items-center">
        <b>Gender</b>
      </div>
      <div class="col-8 ">
        <p-dropdown styleClass="form-control mt-2 w-100" [options]="genderOptions" placeholder="Select type..." formControlName="gender"></p-dropdown>
        <app-validation-message [form]="benefitsDependentForm" [path]="'gender'" [name]="'gender'" [label]="'Gender'"> </app-validation-message>
      </div>


      <div class="col-4 d-flex align-items-center">
        <b>Relationship</b>
      </div>
      <div class="col-8 ">
        <p-dropdown styleClass="form-control mt-2 w-100" [options]="relationshipOptions" placeholder="Select type..." formControlName="relationship"></p-dropdown>
        <app-validation-message [form]="benefitsDependentForm" [path]="'relationship'" [name]="'relationship'" [label]="'Relationship'"> </app-validation-message>
      </div>

      <div class="col-4 d-flex align-items-center">
        <b>Date of Birth</b>
      </div>
      <div class="col-8 ">

        <p-calendar dateMask
                    inputStyleClass="form-control mt-2"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1900:2100'"
                    [showTime]="false"
                    placeholder="Select date..."
                    formControlName="dateOfBirth"
                    dataType="string"></p-calendar>
        <app-validation-message [form]="benefitsDependentForm" [path]="'dateOfBirth'" [name]="'dateOfBirth'" [label]="'Date of Birth'"> </app-validation-message>
      </div>

      <div class="col-4 d-flex align-items-center">
        <b>SSN</b>
      </div>
      <div class="col-8 ">
        <input id="ssnInput" type="text" class="form-control mt-2" formControlName="ssn" />
        <app-validation-message [form]="benefitsDependentForm" [path]="'ssn'" [name]="'ssn'" [label]="'SSN'"> </app-validation-message>
      </div>

      <div class="col-4 d-flex align-items-center">
        <b>Fulltime College Student</b>
      </div>
      <div class="col-8">
        <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isFullTimeCollegeStudent"></p-checkbox>
      </div>


      <ng-container *ngIf="benefitsDependentForm.get('isFullTimeCollegeStudent').value">
        <div class="col-4 d-flex align-items-center">
          <b>College Start Date</b>
        </div>
        <div class="col-8 ">

          <p-calendar dateMask
                      inputStyleClass="form-control mt-2"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      styleClass="w-100"
                      panelStyleClass="calendarPanel"
                      appendTo="body"
                      [hourFormat]="12"
                      [yearRange]="'1920:2100'"
                      [showTime]="false"
                      placeholder="Select date..."
                      formControlName="collegeStartDate"
                      dataType="string"></p-calendar>
          <app-validation-message [form]="benefitsDependentForm" [path]="'collegeStartDate'" [name]="'collegeStartDate'" [label]="'College Start Date'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Expected Graduation Date</b>
        </div>
        <div class="col-8 ">

          <p-calendar dateMask
                      inputStyleClass="form-control mt-2"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      styleClass="w-100"
                      panelStyleClass="calendarPanel"
                      appendTo="body"
                      [hourFormat]="12"
                      [yearRange]="'1920:2100'"
                      [showTime]="false"
                      placeholder="Select date..."
                      formControlName="expectedGraduationDate"
                      dataType="string"></p-calendar>
          <app-validation-message [form]="benefitsDependentForm" [path]="'expectedGraduationDate'" [name]="'expectedGraduationDate'" [label]="'Expected Graduation Date'"> </app-validation-message>
        </div>

      </ng-container>


      <div class="col-4 d-flex align-items-center">
        <b>Disabled</b>
      </div>
      <div class="col-8">
        <p-checkbox styleClass="mt-2" [binary]="true" formControlName="disabled"></p-checkbox>
      </div>

      <ng-container *ngIf="benefitsDependentForm.get('disabled').value">
        <div class="col-4 d-flex align-items-center">
          <b>Disabled Date</b>
        </div>
        <div class="col-8 ">

          <p-calendar inputStyleClass="form-control mt-2"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      styleClass="w-100"
                      panelStyleClass="calendarPanel"
                      appendTo="body"
                      [hourFormat]="12"
                      [yearRange]="'1920:2100'"
                      [showTime]="false"
                      placeholder="Select date..."
                      formControlName="disabledDate"
                      dataType="string"></p-calendar>
          <app-validation-message [form]="benefitsDependentForm" [path]="'disabledDate'" [name]="'disabledDate'" [label]="'Disabled Date'"> </app-validation-message>
        </div>

      </ng-container>


      <div class="col-4 d-flex align-items-center">
        <b>Tobacco User</b>
      </div>
      <div class="col-8">
        <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isTobbacoUser"></p-checkbox>
      </div>


      <div class="col-4 d-flex align-items-center">
        <b>Same Address as Employee?</b>
      </div>
      <div class="col-8">
        <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isAddressSame"></p-checkbox>
      </div>

      <ng-container *ngIf="!benefitsDependentForm.get('isAddressSame').value">

        <div class="col-4 d-flex align-items-center">
          <b>Address</b>
        </div>
        <div class="col-8 ">
          <input id="addressInput" type="text" class="form-control mt-2" formControlName="address" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'address'" [name]="'address'" [label]="'Address'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Address Extra</b>
        </div>
        <div class="col-8 ">
          <input id="addressExtraInput" type="text" class="form-control mt-2" formControlName="addressExtra" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'addressExtra'" [name]="'addressExtra'" [label]="'Address Extra'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>City</b>
        </div>
        <div class="col-8 ">
          <input id="cityInput" type="text" class="form-control mt-2" formControlName="city" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'city'" [name]="'city'" [label]="'City'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Province</b>
        </div>
        <div class="col-8 ">
          <input id="provinceInput" type="text" class="form-control mt-2" formControlName="province" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'province'" [name]="'province'" [label]="'Province'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>County</b>
        </div>
        <div class="col-8 ">
          <input id="countyInput" type="text" class="form-control mt-2" formControlName="county" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'county'" [name]="'county'" [label]="'County'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>State</b>
        </div>
        <div class="col-8 ">
          <input id="stateInput" type="text" class="form-control mt-2" formControlName="state" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'state'" [name]="'state'" [label]="'State'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Country</b>
        </div>
        <div class="col-8 ">
          <input id="countryInput" type="text" class="form-control mt-2" formControlName="country" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'country'" [name]="'country'" [label]="'Country'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Zip Code</b>
        </div>
        <div class="col-8 ">
          <input id="zipCodeInput" type="text" class="form-control mt-2" formControlName="zipCode" />
          <app-validation-message [form]="benefitsDependentForm" [path]="'zipCode'" [name]="'zipCode'" [label]="'Zip Code'"> </app-validation-message>
        </div>

      </ng-container>

    </div>

    <button class="btn btn-primary mt-3" id="saveDependentButton" [class.disabled]="benefitsDependentForm.invalid" (click)="saveDependent()">Save</button>
  </form>
</p-dialog>



<p-dialog [header]="'Edit  Enrollment Plans'" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="enrollmentSetEdit">

  <form class="" [formGroup]="enrollmentSetForm" *ngIf="enrollmentSetForm && enrollmentSetEdit">
    <div class="row m-0">


      <div class="col-4 d-flex align-items-center">
        <b>Life Changing Event Type</b>
      </div>
      <div class="col-8 ">
        <p-dropdown styleClass="form-control mt-2 w-100" [options]="lifeEventOptions" placeholder="Select life event..." formControlName="lifeChangingEventID"></p-dropdown>
        <app-validation-message [form]="enrollmentSetForm" [path]="'lifeChangingEventID'" [name]="'lifeChangingEventID'" [label]="'Life Changing Event Type'"> </app-validation-message>
      </div>

      <div class="col-4 d-flex align-items-center" *ngIf="enrollmentSetForm.get('lifeChangingEventID').value == 7">
        <b>Life Changing Event Other</b>
      </div>
      <div class="col-8 " *ngIf="enrollmentSetForm.get('lifeChangingEventID').value == 7">
        <input id="lifeChangingEventOther" type="text" class="form-control mt-2" formControlName="lifeChangingEventOther" />
        <app-validation-message [form]="enrollmentSetForm" [path]="'lifeChangingEventOther'" [name]="'lifeChangingEventOther'" [label]="'Life Changing Event Other'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center" *ngIf="enrollmentSetForm.get('lifeChangingEventID').value > 2">
        <b>Event date:</b>
      </div>
      <div class="col-8 " *ngIf="enrollmentSetForm.get('lifeChangingEventID').value > 2">

        <p-calendar dateMask
                    inputStyleClass="form-control mt-2"
                    [monthNavigator]="true"
                    [yearNavigator]="false"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1900:2100'"
                    [showTime]="false"
                    placeholder="Select event date..."
                    formControlName="lifeChangingEventDate"
                    dataType="string"></p-calendar>
        <app-validation-message [form]="enrollmentSetForm" [path]="'lifeChangingEventDate'" [name]="'lifeChangingEventDate'" [label]="'Life Changing Event Date'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center" >
        <b>Activated date:</b>
      </div>
      <div class="col-8 " >

        <p-calendar dateMask
                    inputStyleClass="form-control mt-2"
                    [monthNavigator]="true"
                    [yearNavigator]="false"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1900:2100'"
                    [showTime]="false"
                    placeholder="Select activated date..."
                    formControlName="activatedDate"
                    dataType="string"></p-calendar>
        <app-validation-message [form]="enrollmentSetForm" [path]="'activatedDate'" [name]="'activatedDate'" [label]="'Activated Date'"> </app-validation-message>
      </div>


      <ng-container formArrayName="enrollments">
        <ng-container  *ngFor="let enrollmentForm of enrollmentsFormArray.controls; let i = index" [formGroup]="enrollmentForm">
          <div class="col-12 d-flex mt-3">
            <h6 class="font-weight-bolder">{{enrollmentForm.get('benefitPlanType').value}} - {{enrollmentForm.get('benefitPlan').value}}</h6>
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Start date:</b>
          </div>
          <div class="col-8 ">
            <p-calendar dateMask
                        inputStyleClass="form-control mt-2"
                        [monthNavigator]="true"
                        [yearNavigator]="false"
                        styleClass="w-100"
                        panelStyleClass="calendarPanel"
                        appendTo="body"
                        [hourFormat]="12"
                        [yearRange]="'1900:2100'"
                        [showTime]="false"
                        placeholder="Select start date..."
                        formControlName="dateStart"
                        dataType="string"></p-calendar>
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>End date:</b>
          </div>
          <div class="col-8 ">
            <p-calendar dateMask
                        inputStyleClass="form-control mt-2"
                        [monthNavigator]="true"
                        [yearNavigator]="false"
                        styleClass="w-100"
                        panelStyleClass="calendarPanel"
                        appendTo="body"
                        [hourFormat]="12"
                        [yearRange]="'1900:2100'"
                        [showTime]="false"
                        placeholder="Select end date..."
                        formControlName="dateEnd"
                        dataType="string"></p-calendar>
          </div>

          <div class="col-4 d-flex align-items-center">
            <b>Cost Per Pay Period:</b>
          </div>
          <div class="col-8 ">
            <input class="form-control mt-2" type="number" step="0.01" min="0" formControlName="costPerPayPeriod" />
          </div>
          <div class="col-4 d-flex align-items-center" >
            <b>Employer Contribution:</b>
          </div>
          <div class="col-8 " >
            <input class="form-control mt-2" type="number" step="0.01" min="0" formControlName="employerContribution" />
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Additional employee election:</b>
          </div>
          <div class="col-8 ">
            <input class="form-control mt-2" type="number" step="0.01" min="0" formControlName="employeePerPayElection" />
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Coverage:</b>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2" [options]="coverageLevels" formControlName="coverageLevelID"></p-dropdown>
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Dependents:</b>
          </div>
          <div class="col-8 ">
            <p-multiSelect [options]="enrollmentSetDependents" formControlName="dependentIds" [filter]="true" defaultLabel="None" styleClass="p-dropdown-sm w-100 d-flex  mt-2">
            </p-multiSelect>

          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Generate docs:</b>
          </div>
          <div class="col-8 ">
            <p-checkbox styleClass="mt-2" [binary]="true" formControlName="generateNewDocumentToSign"></p-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <button class="btn btn-primary mt-3" id="saveEnrollmentButton" [class.disabled]="enrollmentSetForm.invalid" (click)="saveEnrollmentSet()">Save</button>
  </form>
</p-dialog>
