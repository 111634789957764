import { PagingResultsModel } from '../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { deepClone } from 'src/app/helpers/utils';
import { EmployeeReviewTemplateCategoryModel, EmployeeReviewTemplateKPIModel, EmployeeReviewTemplateKRAModel, EmployeeReviewTemplateMeasureableModel, EmployeeReviewTemplateModel } from '../../models/employeeReviewTemplateModels';
import { CompletedKraModel, EmployeeReviewInfoModel, EmployeeReviewModel, EmployeeReviewOpenPeriod, EmployeeReviewOversightLoadModel, KraReportModel, OverseenEmployeeModel, SecondOversightReviewViewModel } from '../../models/employeeReviewModels';
import { ModifiedSelectItem } from '../../models/utilModels';


@Injectable()
export class EmployeeReviewService {

  constructor(private http: HttpClient) {
  }

  getMyTemplates()
    : Observable<EmployeeReviewTemplateModel[]> {

    return this.http.get<EmployeeReviewTemplateModel[]>(`${environment.apiServer.webApiURL}/EmployeeReview/GetMyTemplates`);
  }

  postReview(model: EmployeeReviewModel) {
    return this.http.post<EmployeeReviewModel>(`${environment.apiServer.webApiURL}/EmployeeReview/PostEmployeeReview`, model);
  }

  postOversightReview(model: EmployeeReviewModel) {
    return this.http.post<EmployeeReviewModel>(`${environment.apiServer.webApiURL}/EmployeeReview/PostOversightReview`, model);
  }

  getReview(reviewId: number) {
    return this.http.get<EmployeeReviewOversightLoadModel>(`${environment.apiServer.webApiURL}/EmployeeReview/${reviewId}`);
  }
  getOversightReview(reviewId: number) {
    return this.http.get<EmployeeReviewOversightLoadModel>(`${environment.apiServer.webApiURL}/EmployeeReview/Oversight/${reviewId}`);
  }

  getOversightData(employeeId: number) {
    return this.http.get<EmployeeReviewOversightLoadModel>(`${environment.apiServer.webApiURL}/EmployeeReview/GetOversightData/${employeeId}`);
  }

  autoSaveEmployeeReview(model: EmployeeReviewModel) {
    return this.http.post<EmployeeReviewModel>(`${environment.apiServer.webApiURL}/EmployeeReview/AutoSaveEmployeeReview`, model);
  }

  autoSaveOversightReview(model: EmployeeReviewModel) {
    return this.http.post<EmployeeReviewModel>(`${environment.apiServer.webApiURL}/EmployeeReview/AutoSaveOversightReview`, model);
  }


  getOverseenEmployee(userId: number) {
    return this.http.get<OverseenEmployeeModel>(`${environment.apiServer.webApiURL}/EmployeeReview/GetEmployee/${userId}`);
  }
  getReviews(userId: number, filter: any)
    : Observable<PagingResultsModel<EmployeeReviewInfoModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    return this.http.post<PagingResultsModel<EmployeeReviewInfoModel>>(`${environment.apiServer.webApiURL}/EmployeeReview/GetReviews/${userId}`,
      filter)
  }

  getCompletedKraList(filter: any): Observable<PagingResultsModel<CompletedKraModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CompletedKraModel>>(`${environment.apiServer.webApiURL}/EmployeeReview/CompletedKraList`,
      filter)
  }

  saveMeetsExpectations(review: CompletedKraModel): Observable<any> {
    return this.http.put<CompletedKraModel>(`${environment.apiServer.webApiURL}/EmployeeReview/SaveMeetsExpectations/${review.oversightReviewID}`, review);
  }

  saveOversightReview(review: SecondOversightReviewViewModel): Observable<any> {
    return this.http.post<SecondOversightReviewViewModel>(`${environment.apiServer.webApiURL}/EmployeeReview/SaveOversightReview/${review.employeeReviewOversightID}`, review);
  }

  getReviewOpenPeriod(userId: number) {
    return this.http.get<EmployeeReviewOpenPeriod>(`${environment.apiServer.webApiURL}/EmployeeReview/GetReviewOpenPeriod/${userId}`);
  }

  getKraList(filter: any): Observable<PagingResultsModel<KraReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<KraReportModel>>(`${environment.apiServer.webApiURL}/EmployeeReview/KraList`,
      filter)
  }

  getTemplateFilter(): Observable<ModifiedSelectItem[]> {
    return this.http.get<ModifiedSelectItem[]>(`${environment.apiServer.webApiURL}/EmployeeReview/TemplateFilter`);
  }
}
