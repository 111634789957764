import { FBFormModel, FBFormTypeModel, FBFormFieldTypeModel, FBFormAssignmentModel, FBFormSubmissionModel, FBFormFieldValueModel, FBFormRoleAssignmentModel, InquirySimpleModel, FBMappingColumnModel, FBFormFieldModel, FBFormFieldSection, UsersWithFormsModel, SimpleFormsListModel, FBFormLanguageModel, FBFormJobTitleAssignmentModel } from './../../../models/formBuilderModels';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import { deepClone, getFormattedTime } from 'src/app/helpers/utils';
import { ValidatorRequiredNotEmptyIfBool, ValidatorRequiredNotEmptyIfFalse } from 'src/app/helpers/validators';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import {
  clearForm, deleteFBForm, deleteFBFormFail,
  deleteFBFormSuccess, getFBForm, getFBFormFail,
  getFBFormSuccess, getFBFormTable, getFBFormTableFail,
  getFBFormTableSuccess, getFormFieldTypesList, getFormFieldTypesListFail,
  getFormFieldTypesListSuccess, getFormTypesList, getFormTypesListFail,
  getFormTypesListSuccess, removeFBFormField, saveFBForm, saveFBFormFail,
  saveFBFormSuccess, updateFBFormField, updateFBFormForm, getAvailableForms,
  getAvailableFormsSuccess, getAvailableFormsFail, updateFBFormSubmissionForm,
  saveFBFormSubmissionForm, saveFBFormSubmissionFormFail,
  saveFBFormSubmissionFormSuccess, updateFBFormSubmission,
  getFBFormSubmissionFail, getFBFormSubmissionSuccess, getFBFormSubmission,
  loadFormSubmissionDocToSign, loadFormSubmissionDocToSignSuccess, loadFormSubmissionDocToSignFail,
  signFormSubmissionDoc, signFormSubmissionDocSuccess, signFormSubmissionDocFail, finishFBFormSubmissionForm,
  finishFBFormSubmissionFormSuccess, finishFBFormSubmissionFormFail, getFBFormAssignmentTable,
  getFBFormAssignmentTableSuccess, getFBFormAssignmentTableFail,
  getAllForms, getAllFormsSuccess, getAllFormsFail,
  addFBFormAssignment, addFBFormAssignmentSuccess, addFBFormAssignmentFail, loadFormDocSuccess, loadFormDocFail, loadFormDoc,
  clearFormFill,
  getFBFormRoleAssignment,
  getFBFormRoleAssignmentSuccess,
  getFBFormRoleAssignmentFail,
  updateFBFormRoleAssignment,
  updateFBFormRoleAssignmentSuccess,
  updateFBFormRoleAssignmentFail,
  getInquiryList,
  getInquiryListSuccess,
  getMappingColumnsList,
  getMappingColumnsListSuccess,
  getMappingColumnsListFail,
  getAllUsersWithForms,
  getAllUsersWithFormsSuccess,
  getAllUsersWithFormsFail,
  getUsersWithFormAssignmentTable,
  getUsersWithFormAssignmentTableSuccess,
  getUsersWithFormAssignmentTableFail,
  clearFormAssignmentTable,
  getFormsFilterFail,
  getFormsFilterSuccess,
  getFormsFilter,
  updateFBFormFieldGroup,
  removeFBFormFieldGroup,
  addFBFormLanguage,
  removeFBFormLanguage,
  updatedSavingSubmissionForm,
  toggleFormAssignmentArchived,
  toggleFormAssignmentArchivedFail,
  setFormAssignmentCanOptout,
  setFormAssignmentCanOptoutSuccess,
  getFBFormJobTitleAssignment,
  getFBFormJobTitleAssignmentSuccess,
  getFBFormJobTitleAssignmentFail,
  updateFBFormJobTitleAssignment,
  updateFBFormJobTitleAssignmentSuccess,
  updateFBFormJobTitleAssignmentFail,
  toggleFormAssignmentArchivedSuccess,

} from './form-builder.actions';

export interface FormBuilderState {
  forms: PagingResultsModel<FBFormModel>;
  loadingForms: boolean;
  form: FBFormModel;
  savingForm: boolean;
  deletingForm: boolean;
  loadingForm: boolean;

  formDocArrayBuffer: ArrayBuffer;
  savingFormDocFile: boolean;

  formTypes: FBFormTypeModel[];
  loadingFormTypes: boolean;
  formFieldTypes: FBFormFieldTypeModel[];
  loadingFormFieldTypes: boolean;

  usersWithForms: UsersWithFormsModel[];

  availableForms: FBFormAssignmentModel[];
  loadingAvailableForms: boolean;

  formSubmission: FBFormSubmissionModel;
  loadingFormSubmission: boolean;
  savingFormSubmission: boolean;

  formErrors: FormError[];

  loadingDoc: boolean;
  loadingSign: boolean;
  docArrayBuffer: ArrayBuffer;


  formsAssignment: PagingResultsModel<FBFormAssignmentModel>;
  usersWithFormsAssignment: PagingResultsModel<UsersWithFormsModel>;
  loadingFormsAssignment: boolean;


  formsList: FBFormModel[];
  simpleFormsList: SimpleFormsListModel[];
  loadingFormsList: boolean;

  mappingColumnsList: FBMappingColumnModel[];
  loadingMappingColumnsList: boolean;

  addingFormAssignment: boolean;

  updatingFormRoleAssignment: boolean;
  formRoleAssignment: FBFormRoleAssignmentModel;

  updatingFormJobTitleAssignment: boolean;
  formJobTitleAssignment: FBFormJobTitleAssignmentModel;

  inquiryList: InquirySimpleModel[];
}

const initialState: FormBuilderState = {
  formErrors: [],
  forms: null,
  loadingForms: false,
  form: null,
  savingForm: false,
  deletingForm: false,
  loadingForm: false,

  formDocArrayBuffer: null,
  savingFormDocFile: false,

  formTypes: [],
  loadingFormTypes: false,
  formFieldTypes: [],
  loadingFormFieldTypes: false,

  usersWithForms: [],

  availableForms: [],
  loadingAvailableForms: false,
  savingFormSubmission: false,

  formSubmission: null,
  loadingFormSubmission: false,


  loadingDoc: false,
  loadingSign: false,
  docArrayBuffer: null,

  formsAssignment: null,
  usersWithFormsAssignment: null,
  loadingFormsAssignment: false,
  mappingColumnsList: null,
  loadingMappingColumnsList: false,

  formsList: null,
  simpleFormsList: null,
  loadingFormsList: false,
  addingFormAssignment: false,

  updatingFormRoleAssignment: false,
  formRoleAssignment: null,

  updatingFormJobTitleAssignment: false,
  formJobTitleAssignment: null,

  inquiryList: [],
};

export const formBuilderReducer = createReducer(initialState,
  on(updateFBFormForm, (state, action) => {
    let form: FBFormModel = { ...state.form, ...action.formValues };
    if (!form.formFields) {
      form.formFields = [];
    }
    const newState = { ...state, ...{ form, formErrors: action.formErrors } };
    return newState;
  }),

  on(saveFBForm, (state, action) => {
    return {
      ...state,
      savingForm: true
    };
  }),
  on(saveFBFormSuccess, (state, action) => {
    return {
      ...state,
      form: action.form,
      savingForm: false
    };
  }),
  on(saveFBFormFail, (state, action) => {
    return {
      ...state,
      savingForm: false
    };
  }),

  on(getFBFormTable, (state, action) => {
    return {
      ...state,
      loadingForms: true
    };
  }),
  on(getFBFormTableSuccess, (state, action) => {
    return {
      ...state,
      forms: action.forms,
      loadingForms: false
    };
  }),
  on(getFBFormTableFail, (state, action) => {
    return {
      ...state,
      loadingForms: false
    };
  }),

  on(getFBForm, (state, action) => {
    return {
      ...state,
      loadingForm: true
    };
  }),
  on(getFBFormSuccess, (state, action) => {
    let form: FBFormModel;
    if (!action.form) {
      form = {};
    } else {
      form = deepClone(action.form);
    }
    if (!form.formFields) {
      form.formFields = [];
    }
    return {
      ...state,
      form,
      loadingForm: false
    };
  }),
  on(getFBFormFail, (state, action) => {
    return {
      ...state,
      loadingForm: false
    };
  }),

  on(deleteFBForm, (state, action) => {
    return {
      ...state,
      deletingForm: true
    };
  }),
  on(deleteFBFormSuccess, (state, action) => {
    return {
      ...state,
      form: null,
      deletingForm: false
    };
  }),
  on(deleteFBFormFail, (state, action) => {
    return {
      ...state,
      deletingForm: false
    };
  }),

  on(clearForm, (state, action) => {
    return {
      ...state,
      form: null
    };
  }),

  on(clearFormFill, (state, action) => {
    return {
      ...state,
      form: null,
      formSubmission: null
    };
  }),

  on(getFormTypesList, (state, action) => {
    return {
      ...state,
      loadingFormTypes: true
    };
  }),
  on(getFormTypesListSuccess, (state, action) => {
    return {
      ...state,
      formTypes: action.formTypesList,
      loadingFormTypes: false
    };
  }),
  on(getFormTypesListFail, (state, action) => {
    return {
      ...state,
      loadingFormTypes: false
    };
  }),

  on(getFormFieldTypesList, (state, action) => {
    return {
      ...state,
      loadingFormFieldTypes: true
    };
  }),
  on(getFormFieldTypesListSuccess, (state, action) => {
    return {
      ...state,
      formFieldTypes: action.formFieldTypesList,
      loadingFormFieldTypes: false
    };
  }),
  on(getFormFieldTypesListFail, (state, action) => {
    return {
      ...state,
      loadingFormFieldTypes: false
    };
  }),


  on(getMappingColumnsList, (state, action) => {
    return {
      ...state,
      loadingMappingColumnsList: true
    };
  }),
  on(getMappingColumnsListSuccess, (state, action) => {
    return {
      ...state,
      mappingColumnsList: action.mappingColumnsList,
      loadingMappingColumnsList: false
    };
  }),
  on(getMappingColumnsListFail, (state, action) => {
    return {
      ...state,
      loadingMappingColumnsList: false
    };
  }),

  on(updateFBFormField, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }

    if (!form.formFields) {
      form.formFields = [];
    }
    let fieldIndex;
    if (action.field.id) {
      fieldIndex = form.formFields.findIndex(x => x.id === action.field.id);
    } else {
      fieldIndex = form.formFields.findIndex(x => x.localID === action.field.localID);
    }

    if (fieldIndex >= 0) {
      const changedOrder = form.formFields[fieldIndex].fieldOrder !== action.field.fieldOrder;
      form.formFields[fieldIndex] = { ...form.formFields[fieldIndex], ...action.field };
      if (changedOrder) {
        form.formFields.forEach((f, i) => {
          if (f.fieldOrder >= action.field.fieldOrder && i !== fieldIndex) {
            f.fieldOrder = f.fieldOrder + 1;
          }
        });
      }
    } else {
      form.formFields.push(action.field);
    }

    const newState = { ...state, ...{ form } };
    return newState;
  }),


  on(updateFBFormFieldGroup, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }

    if (!form.formFieldGroups) {
      form.formFieldGroups = [];
    }
    let fieldGroupIndex;
    if (action.fieldGroup.id) {
      fieldGroupIndex = form.formFieldGroups.findIndex(x => x.id === action.fieldGroup.id);
    } else {
      fieldGroupIndex = form.formFieldGroups.findIndex(x => x.localID === action.fieldGroup.localID);
    }

    if (fieldGroupIndex >= 0) {
      const changedOrder = form.formFieldGroups[fieldGroupIndex].order !== action.fieldGroup.order;
      form.formFieldGroups[fieldGroupIndex] = { ...form.formFieldGroups[fieldGroupIndex], ...action.fieldGroup };
      if (changedOrder) {
        form.formFieldGroups.forEach((f, i) => {
          if (f.order >= action.fieldGroup.order && i !== fieldGroupIndex) {
            f.order = f.order + 1;
          }
        });
      }
    } else {
      form.formFieldGroups.push(action.fieldGroup);
    }

    const newState = { ...state, ...{ form } };
    return newState;
  }),


  on(removeFBFormFieldGroup, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }
    if (!form.formFields) {
      return state;
    }

    let fieldIndex;
    if (action.fieldGroup.id) {
      fieldIndex = form.formFieldGroups.findIndex(x => x.id === action.fieldGroup.id);
    } else {
      fieldIndex = form.formFieldGroups.findIndex(x => x.localID === action.fieldGroup.localID);
    }

    form.formFieldGroups.splice(fieldIndex, 1);

    const newState = { ...state, ...{ form } };
    return newState;
  }),

  on(removeFBFormField, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }
    if (!form.formFields) {
      return state;
    }

    let fieldIndex;
    if (action.field.id) {
      fieldIndex = form.formFields.findIndex(x => x.id === action.field.id);
    } else {
      fieldIndex = form.formFields.findIndex(x => x.localID === action.field.localID);
    }

    form.formFields.splice(fieldIndex, 1);

    const newState = { ...state, ...{ form } };
    return newState;
  }),

  on(addFBFormLanguage, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }
    if (!form.formLanguages) {
      form.formLanguages = [];
    }

    let languageIndex;
    if (action.language.id) {
      languageIndex = form.formLanguages.findIndex(x => x.languageID === action.language.languageID);
    }

    if (languageIndex < 0) {
      form.formLanguages.push(action.language);
    }
    const newState = { ...state, ...{ form } };
    return newState;
  }),


  on(removeFBFormLanguage, (state, action) => {
    let form: FBFormModel;
    if (!state.form) {
      form = {};
    } else {
      form = deepClone(state.form);
    }
    if (!form.formLanguages) {
      return state;
    }

    let fieldIndex;
    if (action.language.languageID) {
      fieldIndex = form.formLanguages.findIndex(x => x.languageID === action.language.languageID);
    }

    form.formLanguages.splice(fieldIndex, 1);

    const newState = { ...state, ...{ form } };
    return newState;
  }),

  on(getAvailableForms, (state, action) => {
    return {
      ...state,
      loadingAvailableForms: true
    };
  }),
  on(getAvailableFormsSuccess, (state, action) => {
    return {
      ...state,
      availableForms: action.availableForms,
      loadingAvailableForms: false
    };
  }),
  on(getAvailableFormsFail, (state, action) => {
    return {
      ...state,
      loadingAvailableForms: false
    };
  }),

  on(toggleFormAssignmentArchived, (state, action) => {
    return {
      ...state,
      loadingAvailableForms: true
    };
  }),
  on(toggleFormAssignmentArchivedSuccess, (state, action) => {
    return {
      ...state,
      loadingAvailableForms: false
    };
  }),
  on(toggleFormAssignmentArchivedFail, (state, action) => {
    return {
      ...state,
      loadingAvailableForms: false
    };
  }),

  on(updateFBFormSubmission, (state, action) => {
    let formSubmission = deepClone(state.formSubmission);
    if (!formSubmission) {
      formSubmission = { fbFormFieldValues: [] };
    }
    const newFormSubmission = { ...formSubmission, ...action.formSubmission };
    const newState = { ...state, ...{ formSubmission: newFormSubmission } };
    return newState;
  }),
  on(updateFBFormSubmissionForm, (state, action) => {
    let formSubmission = deepClone(state.formSubmission);
    if (!formSubmission) {
      formSubmission = { fbFormFieldValues: [] };
    }
    if (!formSubmission.fbFormFieldValues) {
      formSubmission.fbFormFieldValues = [];
    }
    // tslint:disable-next-line:forin
    for (const fieldID in action.formValues) {
      const fBFormFieldIndex = formSubmission.fbFormFieldValues.findIndex(x => x.formFieldID.toString() === fieldID);
      const newValue = (action.formValues[fieldID] !== null && action.formValues[fieldID] !== undefined) ? action.formValues[fieldID].toString() : null;
      if (fBFormFieldIndex >= 0) {
        formSubmission.fbFormFieldValues[fBFormFieldIndex].value = newValue;
      } else {
        formSubmission.fbFormFieldValues.push({
          formFieldID: fieldID,
          value: newValue,
        });
      }
    }
    const newState = { ...state, ...{ formSubmission, formErrors: action.formErrors } };
    return newState;
  }),

  on(saveFBFormSubmissionForm, (state, action) => {
    return {
      ...state,
      savingFormSubmission: true
    };
  }),
  on(saveFBFormSubmissionFormSuccess, (state, action) => {
    return {
      ...state,
      formSubmission: action.formSubmission,
      savingFormSubmission: false
    };
  }),
  on(saveFBFormSubmissionFormFail, (state, action) => {
    return {
      ...state,
      savingFormSubmission: false
    };
  }),


  on(finishFBFormSubmissionForm, (state, action) => {
    return {
      ...state,
      savingFormSubmission: true
    };
  }),
  on(finishFBFormSubmissionFormSuccess, (state, action) => {
    return {
      ...state,
      formSubmission: action.formSubmission,
      savingFormSubmission: false
    };
  }),
  on(finishFBFormSubmissionFormFail, (state, action) => {
    return {
      ...state,
      savingFormSubmission: false
    };
  }),
  on(updatedSavingSubmissionForm, (state, action) => {
    return {
      ...state,
      savingFormSubmission: action.savingFormSubmission
    };
  }),

  on(getFBFormSubmission, (state, action) => {
    return {
      ...state,
      loadingFormSubmission: true
    };
  }),
  on(getFBFormSubmissionSuccess, (state, action) => {
    return {
      ...state,
      formSubmission: action.formSubmission,
      loadingFormSubmission: false
    };
  }),
  on(getFBFormSubmissionFail, (state, action) => {
    return {
      ...state,
      loadingFormSubmission: false
    };
  }),
  on(loadFormSubmissionDocToSign, (state, action) => {
    return {
      ...state,
      loadingDoc: true
    };
  }),
  on(loadFormSubmissionDocToSignSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),

  on(loadFormSubmissionDocToSignFail, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),
  on(signFormSubmissionDoc, (state, action) => {
    return {
      ...state,
      loadingSign: true
    };
  }),
  on(signFormSubmissionDocSuccess, (state, action) => {
    return {
      ...state,
      docSignedArrayBuffer: action.docSigned,
      loadingSign: false
    };
  }),
  on(signFormSubmissionDocFail, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),
  on(clearFormAssignmentTable, (state, action) => {
    return {
      ...state,
      formsAssignment: null
    };
  }),
  on(getFBFormAssignmentTable, (state, action) => {
    return {
      ...state,
      loadingFormsAssignment: true
    };
  }),
  on(getFBFormAssignmentTableSuccess, (state, action) => {
    return {
      ...state,
      formsAssignment: action.formsAssignment,
      loadingFormsAssignment: false
    };
  }),
  on(getFBFormAssignmentTableFail, (state, action) => {
    return {
      ...state,
      loadingFormsAssignment: false
    };
  }),
  on(setFormAssignmentCanOptoutSuccess, (state, action) => {

    let formsAssignment = deepClone(state.formsAssignment);
    if (formsAssignment && formsAssignment.result) {
      const formAssignmentOnTheList = formsAssignment.result.find(x => x.id == action.formAssignment.id);
      if (formAssignmentOnTheList) {
        formAssignmentOnTheList.canOptOut = action.formAssignment.canOptOut;
      }
    }
    return {
      ...state,
      formsAssignment: formsAssignment,
    };
  }),

  on(getUsersWithFormAssignmentTable, (state, action) => {
    return {
      ...state,
      loadingFormsAssignment: true
    };
  }),
  on(getUsersWithFormAssignmentTableSuccess, (state, action) => {
    return {
      ...state,
      usersWithFormsAssignment: action.formsAssignment,
      loadingFormsAssignment: false
    };
  }),
  on(getUsersWithFormAssignmentTableFail, (state, action) => {
    return {
      ...state,
      loadingFormsAssignment: false
    };
  }),
  on(getAllForms, (state, action) => {
    return {
      ...state,
      loadingFormsList: true
    };
  }),
  on(getAllFormsSuccess, (state, action) => {
    return {
      ...state,
      formsList: action.forms,
      loadingFormsList: false
    };
  }),
  on(getAllFormsFail, (state, action) => {
    return {
      ...state,
      loadingFormsList: false
    };
  }),
  on(getFormsFilter, (state, action) => {
    return {
      ...state,
      loadingFormsList: true
    };
  }),
  on(getFormsFilterSuccess, (state, action) => {
    return {
      ...state,
      simpleFormsList: action.forms,
      loadingFormsList: false
    };
  }),
  on(getFormsFilterFail, (state, action) => {
    return {
      ...state,
      loadingFormsList: false
    };
  }),
  on(getAllUsersWithFormsSuccess, (state, action) => {
    return {
      ...state,
      usersWithForms: action.forms,
      loadingFormsList: false
    };
  }),
  on(getAllUsersWithFormsFail, (state, action) => {
    return {
      ...state,
      loadingFormsList: false
    };
  }),

  on(addFBFormAssignment, (state, action) => {
    return {
      ...state,
      addingFormAssignment: true
    };
  }),
  on(addFBFormAssignmentSuccess, (state, action) => {
    return {
      ...state,
      addingFormAssignment: false
    };
  }),
  on(addFBFormAssignmentFail, (state, action) => {
    return {
      ...state,
      addingFormAssignment: false
    };
  }),
  on(loadFormDoc, (state, action) => {
    return {
      ...state,
      loadingDoc: true
    };
  }),
  on(loadFormDocSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),
  on(loadFormDocFail, (state, action) => {
    return {
      ...state,
      loadingDoc: false
    };
  }),


  on(getFBFormRoleAssignment, (state, action) => {
    return {
      ...state,
      updatingFormRoleAssignment: true
    };
  }),
  on(getFBFormRoleAssignmentSuccess, (state, action) => {
    return {
      ...state,
      formRoleAssignment: action.formRoleAssignmentModel,
      updatingFormRoleAssignment: false
    };
  }),
  on(getFBFormRoleAssignmentFail, (state, action) => {
    return {
      ...state,
      updatingFormRoleAssignment: false
    };
  }),

  on(updateFBFormRoleAssignment, (state, action) => {
    return {
      ...state,
      updatingFormRoleAssignment: true
    };
  }),
  on(updateFBFormRoleAssignmentSuccess, (state, action) => {
    return {
      ...state,
      formRoleAssignment: action.formRoleAssignmentModel,
      updatingFormRoleAssignment: false
    };
  }),
  on(updateFBFormRoleAssignmentFail, (state, action) => {
    return {
      ...state,
      updatingFormRoleAssignment: false
    };
  }),


  on(getFBFormJobTitleAssignment, (state, action) => {
    return {
      ...state,
      updatingFormJobTitleAssignment: true
    };
  }),
  on(getFBFormJobTitleAssignmentSuccess, (state, action) => {
    return {
      ...state,
      formJobTitleAssignment: action.formJobTitleAssignmentModel,
      updatingFormJobTitleAssignment: false
    };
  }),
  on(getFBFormJobTitleAssignmentFail, (state, action) => {
    return {
      ...state,
      updatingFormJobTitleAssignment: false
    };
  }),

  on(updateFBFormJobTitleAssignment, (state, action) => {
    return {
      ...state,
      updatingFormJobTitleAssignment: true
    };
  }),
  on(updateFBFormJobTitleAssignmentSuccess, (state, action) => {
    return {
      ...state,
      formJobTitleAssignment: action.formJobTitleAssignmentModel,
      updatingFormJobTitleAssignment: false
    };
  }),
  on(updateFBFormJobTitleAssignmentFail, (state, action) => {
    return {
      ...state,
      updatingFormJobTitleAssignment: false
    };
  }),


  on(getInquiryListSuccess, (state, action) => {
    return {
      ...state,
      inquiryList: action.inquiryList,
    };
  }),
);

export function formBuilderReducerFunc(state: FormBuilderState | undefined, action: Action) {
  return formBuilderReducer(state, action);
}

export function initFormBuilderForm(formModel: FBFormModel) {
  const form = new FormGroup({
    name: new FormControl(formModel.name, [Validators.required, Validators.maxLength(255)]),
    clientOrEmployee: new FormControl(formModel.clientOrEmployee, [Validators.maxLength(255)]),
    description: new FormControl(formModel.description, [Validators.maxLength(255)]),
    formTypeID: new FormControl(formModel.formTypeID, [Validators.required]),
    required: new FormControl(formModel.required, []),
    requireHRSignature: new FormControl(formModel.requireHRSignature, []),
    isAutoAssignable: new FormControl(formModel.isAutoAssignable, []),
    reAssignAfterDays: new FormControl(formModel.reAssignAfterDays, []),
    shouldAssignAsDefault: new FormControl(formModel.shouldAssignAsDefault, []),
    isCredentialDoc: new FormControl(formModel.isCredentialDoc, []),
    requiredForAuthorizationCategoryIDs: new FormControl(formModel.requiredForAuthorizationCategoryIDs, []),
    isForAdult: new FormControl(formModel.isForAdult, []),
    isForChild: new FormControl(formModel.isForChild, []),
    isForNormalClient: new FormControl(formModel.isForNormalClient, []),
    isForEmployeeClient: new FormControl(formModel.isForEmployeeClient, []),
    dueInDays: new FormControl(formModel.dueInDays, []),
    assignAfterOrientation: new FormControl(formModel.assignAfterOrientation, []),
    assignAfterNPI: new FormControl(formModel.assignAfterNPI, []),
    jobTitleIDs: new FormControl(formModel.jobTitleIDs, []),
    insuranceProviderIDs: new FormControl(formModel.insuranceProviderIDs, []),
    otherJobPositions: new FormControl(formModel.otherJobPositions, []),
    neverShowOnClientPortal: new FormControl(formModel.neverShowOnClientPortal, []),
    showSignature: new FormControl(formModel.showSignature, []),
    isI9: new FormControl(formModel.isI9, []),
    languageName: new FormControl(null, []),
    isInternForm: new FormControl(formModel.isInternForm, []),
    canOptOut: new FormControl(formModel.canOptOut, []),
  });


  return form;
}

export function initFormBuilderSubmissionForm(formModel: FBFormModel) {
  const form = new FormGroup({});
  formModel.formFields.forEach(field => {
    createFormFieldControl(field, form, field.id.toString());
  });
  return form;
}

export function fillFormBuilder(form: FormGroup, fbForm: FBFormModel, formSubmissionModel: FBFormSubmissionModel, selectedFormLanguage: FBFormLanguageModel) {
  if (formSubmissionModel && formSubmissionModel.fbFormFieldValues) {
    formSubmissionModel.fbFormFieldValues.forEach(submittedValue => {
      let formControlName = submittedValue.formFieldID.toString();
      let field = fbForm.formFields.find(f => f.id == submittedValue.formFieldID);
      if (((submittedValue.formField && submittedValue.formField.sectionFormFieldID) || (submittedValue.sectionFormFieldID))) {

        let sectionField = formSubmissionModel.fbForm.formFields.find(f => f.id == submittedValue.sectionFormFieldID);
        if (sectionField == null)
          sectionField = formSubmissionModel.fbForm.formFields.find(f => f.id == submittedValue.formField.sectionFormFieldID);

        if (!sectionField.sections) {
          sectionField.sections = [];
        }
        if (submittedValue.entryNumber != null) {
          let section = sectionField.sections.find(f => f.entryNumber == submittedValue.entryNumber);
          if (section == null) {
            section = {
              entryNumber: submittedValue.entryNumber,
              formFields: [],
              readOnly: submittedValue.readOnly
            }
            sectionField.sectionFields.forEach(sf => {
              let fieldClone = deepClone(sf);
              createFormFieldControl(fieldClone, form, `${sf.sectionFormFieldID}_${sf.id}_${submittedValue.entryNumber}`);
              parseField(fieldClone, fbForm, selectedFormLanguage);
              fieldClone.entryNumber = submittedValue.entryNumber;
              section.formFields.push(fieldClone);
            });
            sectionField.sections.push(section);
          }

          field = section.formFields.find(f => f.id == submittedValue.formFieldID);
          formControlName = field.formControlIdentifier;
        }

      }



      let formControl = form.get(formControlName);
      if (formControl) {
        if (submittedValue.value) {
          formControl.setValue(submittedValue.value);
        }
        if (field.formFieldType.formFieldType === 'One Option') {

          if (submittedValue.value && !getOptions(field, selectedFormLanguage).filter(x => !!x.value).some(x => x.value === submittedValue.value)) {
            if (field.allowOther) {
              const newControlOtherOne = form.get(formControlName + "Other");
              newControlOtherOne.setValue(submittedValue.value);
              formControl.setValue('Other');
            }
          }
        } else if (field.formFieldType.formFieldType === 'Many Options' && !field.allowOther) {
          if (submittedValue && submittedValue.value)
            formControl.setValue(submittedValue.value.split('<;>'));
        }
        else if (field.allowOther && field.formFieldType.formFieldType === 'Many Options') {
          let valueOther = '';
          if (submittedValue.value) {
            const possibleOptions = getOptions(field, selectedFormLanguage).filter(x => !!x.value).map(x => x.value);
            const valueOptions = submittedValue.value.split('<;>');
            let selectecValues = [];
            for (const valueOption of valueOptions) {
              if (!possibleOptions.some(x => x === valueOption) && valueOption !== 'Other') {
                valueOther += valueOption + '<;>';
              } else {
                selectecValues.push(valueOption);
              }
            }

            if (valueOther) {
              valueOther = valueOther.substr(0, valueOther.length - 1);
              const newControlOtherOne = form.get(formControlName + "Other");
              newControlOtherOne.setValue(submittedValue.value);
            }
            formControl.setValue(selectecValues);
          }
        }
      }

    });
  }
}
export function parseField(f: FBFormFieldModel, fbForm: FBFormModel, selectedFormLanguage: FBFormLanguageModel) {
  if (f.formFieldType.formFieldType == "Section") {
    if (f.sectionFields) {
      f.sectionFields.forEach(sf => {
        parseField(sf, fbForm, selectedFormLanguage);
      });
    }
  } else {
    f.fieldOptionsList = getOptions(f, selectedFormLanguage);
  }

  if (f.relatedFormFieldID) {
    f.relatedFormField = fbForm.formFields.find(ff => ff.id == f.relatedFormFieldID);
  }

  if (f.requiredIfField) {
    f.requiredIfField = fbForm.formFields.find(ff => ff.id == f.requiredIfFieldID);
  }
}


export function getFieldOptions(field: FBFormFieldModel, selectedFormLanguage: FBFormLanguageModel) {
  let fieldLanguage;
  if (selectedFormLanguage && selectedFormLanguage.languageID) {
    fieldLanguage = field.formFieldLanguages.find(x => x.languageID === selectedFormLanguage.languageID);
    if (fieldLanguage && fieldLanguage.fieldOptions) {
      return fieldLanguage.fieldOptions;
    }
  }

  return field.fieldOptions;
}

export function getOptions(field: FBFormFieldModel, selectedFormLanguage: FBFormLanguageModel) {
  let options = [];

  const fieldOptions = getFieldOptions(field, selectedFormLanguage);
  if (fieldOptions) {
    if (field.optionsTable) {
      options = fieldOptions.split('\n')
        .map(x => ({ label: (x.split('|')[1] ? x.split('|')[1] : x.split('|')[0]), value: x.split('|')[0] }));
    } else {
      options = fieldOptions.split('\n').map(x => ({ label: x, value: x }));
    }

    if (field.allowOther) {
      options.push({ label: 'Other', value: 'Other' });
    }
    if (field.formFieldType.formFieldType === 'OneOption') {
      options.unshift({ label: '-', value: null });
    }
  }
  return options;
}

export function createSection(field: FBFormFieldModel, sectionFields: FBFormFieldModel[], fbForm: FBFormModel, form: FormGroup, readOnly: boolean, selectedFormLanguage: FBFormLanguageModel) {
  let lastRow = 0;
  if (!field.sections) {
    field.sections = [];
  }
  field.sections.forEach(s => {
    if (s.entryNumber > lastRow)
      lastRow = s.entryNumber;
  });
  lastRow++;

  let section: FBFormFieldSection = {
    entryNumber: lastRow,
    formFields: [],
    readOnly: readOnly
  }
  sectionFields.forEach(sf => {
    createFormFieldControl(sf, form, `${field.id}_${sf.id}_${lastRow}`);
    parseField(sf, fbForm, selectedFormLanguage);
    sf.entryNumber = lastRow;
    section.formFields.push(sf);
  });
  field.sections.push(section);
}

export function createFormFieldControl(field: FBFormFieldModel, form: FormGroup, formControlName: string) {
  field.formControlIdentifier = formControlName;
  const newControl = new FormControl();
  if (field.formFieldType.formFieldType != "Section") {
    let validators = [];
    if (!field.readOnly) {
      if (field.isRequired && !field.relatedFormFieldID) {
        validators.push(Validators.required);
      }
      if (field.formFieldType.formFieldType === 'Email') {
        validators.push(Validators.email);
      }
      if (field.formFieldType.formFieldType === 'Phone') {
        validators.push(Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$'));
      }
      if (field.formFieldType.formFieldType === 'Number') {
        validators.push(Validators.pattern('^([0-9]*[.])?[0-9]+$'));
      }
      if (field.formFieldType.formFieldType === 'Text' || field.formFieldType.formFieldType === 'Big Text') {
        if (field.minLength > 0)
          validators.push(Validators.minLength(field.minLength));
        if (field.fieldSize > 0)
          validators.push(Validators.maxLength(field.fieldSize));
      }
    }
    newControl.setValidators(validators);
    form.addControl(formControlName, newControl);
    if (field.allowOther) {
      const newControlOther = new FormControl();
      form.addControl(formControlName + 'Other', newControlOther);
    }
  } else {

    form.addControl(formControlName, newControl);
    //if (field.sectionFields) {
    //  field.sectionFields.forEach(sectionField => {
    //    sectionField.entryNumber = 1;
    //    createFormFieldControl(sectionField, formModel, formSubmissionModel, form, `${field.id}_${sectionField.id}_${sectionField.entryNumber}`);
    //  });
    //}
  }
  return newControl;
}
