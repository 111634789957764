import { FBFormModel, FBFormTypeModel, FBFormFieldTypeModel, FBFormFieldModel, FBFormAssignmentModel, FBFormSubmissionModel, FBFormRoleAssignmentModel, InquirySimpleModel, FBMappingColumnModel, UsersWithFormsModel, SimpleFormsListModel, FBFormFieldGroupModel, FBFormLanguageModel, FBFormJobTitleAssignmentModel } from './../../../models/formBuilderModels';
import { ProspectiveCandidateModel, ProspectiveCandidateContactLogModel, ProspectiveCandidateReferenceModel } from './../../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';


export const updateFBFormForm = createAction('[Form Builder]  Update Form Builder Form',
  props<{ formValues: any; formErrors: FormError[] }>());

export const saveFBForm = createAction('[Form Builder]  Save Form Builder',
  props<{ form: FBFormModel }>());
export const saveFBFormSuccess = createAction('[Form Builder]  Save Form Builder Success',
  props<{ form: FBFormModel }>());
export const saveFBFormFail = createAction('[Form Builder]  Save Form Builder Fail',
  props<{ err: any }>());



export const getFBFormTable = createAction('[Form Builder] Get forms',
  props<{ filter: any }>());
export const getFBFormTableSuccess = createAction('[Form Builder] Get forms list Success',
  props<{ forms: PagingResultsModel<FBFormModel> }>());
export const getFBFormTableFail = createAction('[Form Builder] Get forms list Fail',
  props<{ err: any }>());


export const getFBForm = createAction('[Form Builder] Get  info',
  props<{ formID: number }>());
export const getFBFormSuccess = createAction('[Form Builder] Get info Success',
  props<{ form: FBFormModel }>());
export const getFBFormFail = createAction('[Form Builder] Get info Fail',
  props<{ err: any }>());


export const deleteFBForm = createAction('[Form Builder] Delete ',
  props<{ formID: number }>());
export const deleteFBFormSuccess = createAction('[Form Builder] Delete Success',
  props<{ form: FBFormModel }>());
export const deleteFBFormFail = createAction('[Form Builder] Delete Fail',
  props<{ err: any }>());

  
export const duplicateFBForm = createAction('[Form Builder] Duplicate ',
props<{ formID: number }>());
export const duplicateFBFormSuccess = createAction('[Form Builder] Duplicate Success',
props<{ form: FBFormModel }>());
export const duplicateFBFormFail = createAction('[Form Builder] Duplicate Fail',
props<{ err: any }>());
  

export const clearForm = createAction('[Form Builder] Clear');
export const clearFormFill = createAction('[Form Builder] clearFormFill');


export const getFormTypesList = createAction('[Form Builder] Get Form Type List');
export const getFormTypesListSuccess = createAction('[Form Builder] Get Form Type List Success',
  props<{ formTypesList: FBFormTypeModel[] }>());
export const getFormTypesListFail = createAction('[Form Builder] Get Form Type List Fail',
  props<{ err: any }>());

export const getFormFieldTypesList = createAction('[Form Builder] Get Form Field Type List');
export const getFormFieldTypesListSuccess = createAction('[Form Builder] Get Form Type List Success',
  props<{ formFieldTypesList: FBFormFieldTypeModel[] }>());
export const getFormFieldTypesListFail = createAction('[Form Builder] Get Form Type List Fail',
  props<{ err: any }>());


export const getMappingColumnsList = createAction('[Form Builder] Get All Mapping Columns List');
export const getMappingColumnsListSuccess = createAction('[Form Builder] Get All Mapping Columns List Success',
  props<{ mappingColumnsList: FBMappingColumnModel[] }>());
export const getMappingColumnsListFail = createAction('[Form Builder] Get All Mapping Columns List Fail',
  props<{ err: any }>());

export const updateFBFormField = createAction('[Form Builder] Update Form Field ',
  props<{ field: FBFormFieldModel }>());
export const removeFBFormField = createAction('[Form Builder] Remove Form Field ',
  props<{ field: FBFormFieldModel }>());


export const getAvailableForms = createAction('[Form Builder] Get Available Forms list',
  props<{ userID: number, includeArchived?: boolean }>());
export const getAvailableFormsSuccess = createAction('[Form Builder] Get Available Forms list Success',
  props<{ availableForms: FBFormAssignmentModel[] }>());
export const getAvailableFormsFail = createAction('[Form Builder] Get Available Forms list Fail',
  props<{ err: any }>());



export const getFBFormSubmission = createAction('[Form Builder] Get  FBFormSubmission',
  props<{ formID: number, formSubmissionID?: number, jobApplicationID?: number, prospectiveCandidateID?: number }>());
export const getFBFormSubmissionSuccess = createAction('[Form Builder] Get FBFormSubmission Success',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const getFBFormSubmissionFail = createAction('[Form Builder] Get FBFormSubmission Fail',
  props<{ err: any }>());



export const updateFBFormSubmissionForm = createAction('[Form Builder]  Update Form Submission Form',
  props<{ formValues: any; formErrors: FormError[] }>());
export const updateFBFormSubmission = createAction('[Form Builder]  Update Form Submission ',
  props<{ formSubmission: FBFormSubmissionModel }>());


export const saveFBFormSubmissionForm = createAction('[Form Builder]  Save Form Submission',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const saveFBFormSubmissionFormSuccess = createAction('[Form Builder]  Save Form Submission Success',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const saveFBFormSubmissionFormFail = createAction('[Form Builder]  Save Form Submission Fail',
  props<{ err: any }>());

export const updatedSavingSubmissionForm = createAction('[Form Builder]  updated Saving Form Submission',
  props<{ savingFormSubmission: boolean }>());



export const finishFBFormSubmissionForm = createAction('[Form Builder]  Finish Form Submission',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const finishFBFormSubmissionFormSuccess = createAction('[Form Builder]  Finish Form Submission Success',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const finishFBFormSubmissionFormFail = createAction('[Form Builder]  Finish Form Submission Fail',
  props<{ err: any }>());


export const loadFormSubmissionDocToSign = createAction('[Form Builder] Get  loadFormSubmissionDocToSign',
  props<{ formSubmissionID: number }>());
export const loadFormSubmissionDocToSignSuccess = createAction('[Form Builder] Get loadFormSubmissionDocToSign Success',
  props<{ doc: ArrayBuffer }>());
export const loadFormSubmissionDocToSignFail = createAction('[Form Builder] Get loadFormSubmissionDocToSign Fail',
  props<{ err: any }>());


export const signFormSubmissionDoc = createAction('[Form Builder] Sign FormSubmission Doc',
  props<{ formSubmissionID: number, signedFields: string[] }>());
export const signFormSubmissionDocSuccess = createAction('[Form Builder]  Sign FormSubmission Doc Success',
  props<{ docSigned: ArrayBuffer }>());
export const signFormSubmissionDocFail = createAction('[Form Builder]  Sign FormSubmission Fail',
  props<{ err: any }>());


export const getFBFormAssignmentTable = createAction('[Form Builder] Get forms assignment',
  props<{ filter: any }>());
export const getFBFormAssignmentTableSuccess = createAction('[Form Builder] Get forms assignment Success',
  props<{ formsAssignment: PagingResultsModel<FBFormAssignmentModel> }>());
export const getFBFormAssignmentTableFail = createAction('[Form Builder] Get forms assignment Fail',
  props<{ err: any }>());

export const clearFormAssignmentTable = createAction('[Form Builder] Clear Form assignments Table');

export const getUsersWithFormAssignmentTable = createAction('[Form Builder] Get users with form assignment',
  props<{ filter: any }>());
export const getUsersWithFormAssignmentTableSuccess = createAction('[Form Builder] Get users with form assignment Success',
  props<{ formsAssignment: PagingResultsModel<UsersWithFormsModel> }>());
export const getUsersWithFormAssignmentTableFail = createAction('[Form Builder] Get users with form assignment Fail',
  props<{ err: any }>());


export const getFBFormAssignment = createAction('[Form Builder] Get FormAssignment',
  props<{ formAssignmentID: number }>());
export const getFBFormAssignmentSuccess = createAction('[Form Builder] Get FormAssignment Success',
  props<{ form: FBFormAssignmentModel }>());
export const getFBFormAssignmentFail = createAction('[Form Builder] Get FormAssignment Fail',
  props<{ err: any }>());


export const addFBFormAssignment = createAction('[Form Builder]  add FBFormAssignment',
  props<{ formAssignment: FBFormAssignmentModel }>());
export const addFBFormAssignmentSuccess = createAction('[Form Builder]  add FBFormAssignment Success',
  props<{ formAssignment: FBFormAssignmentModel }>());
export const addFBFormAssignmentFail = createAction('[Form Builder]  add FBFormAssignment Fail',
  props<{ err: any }>());

  
export const setFormAssignmentCanOptout = createAction('[Form Builder]  setFormAssignmentCanOptout',
props<{ formAssignmentID: number,canOptOut:boolean }>());
export const setFormAssignmentCanOptoutSuccess = createAction('[Form Builder]  setFormAssignmentCanOptout Success',
props<{ formAssignment: FBFormAssignmentModel }>());
export const setFormAssignmentCanOptoutFail = createAction('[Form Builder]  setFormAssignmentCanOptout Fail',
props<{ err: any }>());
  


export const getAllForms = createAction('[Form Builder] Get getAllForms');
export const getAllFormsSuccess = createAction('[Form Builder] Get getAllForms Success',
  props<{ forms: FBFormModel[] }>());
export const getAllFormsFail = createAction('[Form Builder] Get getAllForms Fail',
  props<{ err: any }>());

export const getFormsFilter = createAction('[Form Builder] Get getFormsFilter');
export const getFormsFilterSuccess = createAction('[Form Builder] Get getFormsFilter Success',
  props<{ forms: SimpleFormsListModel[] }>());
export const getFormsFilterFail = createAction('[Form Builder] Get getFormsFilter Fail',
  props<{ err: any }>());

export const getAllUsersWithForms = createAction('[Form Builder] Get All Users With Forms',
  props<{ tab: string }>());
export const getAllUsersWithFormsSuccess = createAction('[Form Builder] Get All Users With Forms Success',
  props<{ forms: UsersWithFormsModel[] }>());
export const getAllUsersWithFormsFail = createAction('[Form Builder] Get All Users With Forms Fail',
  props<{ err: any }>());

export const deleteFBFormAssignment = createAction('[Form Builder] deleteFBFormAssignment',
  props<{ formAssignmentID: number }>());
export const deleteFBFormAssignmentSuccess = createAction('[Form Builder] deleteFBFormAssignment Success');
export const deleteFBFormAssignmentFail = createAction('[Form Builder] deleteFBFormAssignment Fail',
  props<{ err: any }>());

export const loadFormDoc = createAction('[Form Builder] Load Form Doc',
  props<{ formSubmissionID: number }>());
export const loadFormDocSuccess = createAction('[Form Builder] Load Form Doc Success',
  props<{ doc: ArrayBuffer }>());
export const loadFormDocFail = createAction('[Form Builder] Load Form Doc Success',
  props<{ err: any }>());


export const getFBFormRoleAssignment = createAction('[Form Builder] Get FBFormRoleAssignment',
  props<{ formID: number }>());
export const getFBFormRoleAssignmentSuccess = createAction('[Form Builder] Get FBFormRoleAssignment Success',
  props<{ formRoleAssignmentModel: FBFormRoleAssignmentModel }>());
export const getFBFormRoleAssignmentFail = createAction('[Form Builder] Get FBFormRoleAssignment Fail',
  props<{ err: any }>());

export const updateFBFormRoleAssignment = createAction('[Form Builder]  add updateFBFormRoleAssignment',
  props<{ formRoleAssignmentModel: FBFormRoleAssignmentModel }>());
export const updateFBFormRoleAssignmentSuccess = createAction('[Form Builder]  add updateFBFormRoleAssignment Success',
  props<{ formRoleAssignmentModel: FBFormRoleAssignmentModel }>());
export const updateFBFormRoleAssignmentFail = createAction('[Form Builder]  add updateFBFormRoleAssignment Fail',
  props<{ err: any }>());


export const getFBFormJobTitleAssignment = createAction('[Form Builder] Get FBFormJobTitleAssignment',
  props<{ formID: number }>());
export const getFBFormJobTitleAssignmentSuccess = createAction('[Form Builder] Get FBFormJobTitleAssignment Success',
  props<{ formJobTitleAssignmentModel: FBFormJobTitleAssignmentModel }>());
export const getFBFormJobTitleAssignmentFail = createAction('[Form Builder] Get FBFormJobTitleAssignment Fail',
  props<{ err: any }>());

export const updateFBFormJobTitleAssignment = createAction('[Form Builder]  add updateFBFormJobTitleAssignment',
  props<{ formJobTitleAssignmentModel: FBFormJobTitleAssignmentModel }>());
export const updateFBFormJobTitleAssignmentSuccess = createAction('[Form Builder]  add updateFBFormJobTitleAssignment Success',
  props<{ formJobTitleAssignmentModel: FBFormJobTitleAssignmentModel }>());
export const updateFBFormJobTitleAssignmentFail = createAction('[Form Builder]  add updateFBFormJobTitleAssignment Fail',
  props<{ err: any }>());


export const getInquiryList = createAction('[Form Builder] Get getInquiryList');
export const getInquiryListSuccess = createAction('[Form Builder] Get getInquiryList Success',
  props<{ inquiryList: InquirySimpleModel[] }>());
export const getInquiryListFail = createAction('[Form Builder] Get getInquiryList Fail',
  props<{ err: any }>());



export const updateFBFormFieldGroup = createAction('[Form Builder] Update Form Field Group ',
  props<{ fieldGroup: FBFormFieldGroupModel }>());
export const removeFBFormFieldGroup = createAction('[Form Builder] Remove Form Field Group ',
  props<{ fieldGroup: FBFormFieldGroupModel }>());


  export const addFBFormLanguage = createAction('[Form Builder] Add Form Language ',
  props<{ language: FBFormLanguageModel }>());
export const removeFBFormLanguage = createAction('[Form Builder] Remove Form Language  ',
  props<{ language: FBFormLanguageModel }>());

export const toggleFormAssignmentArchived = createAction('[Form Builder] Toggle Form Assignment Archived',
  props<{ assignmentId: number }>());
export const toggleFormAssignmentArchivedSuccess = createAction('[Form Builder] Toggle Form Assignment Archived Success');
export const toggleFormAssignmentArchivedFail = createAction('[Form Builder] Toggle Form Assignment Archived Fail',
  props<{ err: any }>());
