<div class=" mt-2 mb-2" >
  <div class="planner-panel">
    <button  class="benefit-btn gray float-right pointer" *ngIf="!readOnly" (click)="newBenefitsDependentInfo()">New Dependent</button>
    <h2 class="mb-3 benefit-headers-font">My Dependents <img src="/assets/img/familyt.png" class="ml-2" style="width:40px"/></h2>
    <p class="description">Add your dependents here to enroll them in benefits.</p>
    <div class="row">
      <div class="col-12 description">

        <i *ngIf="loadingBenefitsDependent" class="material-icons rotate text-muted">loop</i>
        <p-table  [value]="benefitsDependents" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th class="description" width="110px" *ngIf="!readOnly"></th>
                    <th class="description" [pSortableColumn]="'firstName'" >Name
                        <p-sortIcon [field]="'firstName'"></p-sortIcon></th>

                    <th class="description" [pSortableColumn]="'relationship'">Relationship
                      <p-sortIcon [field]="'relationship'"></p-sortIcon>
                    </th>

                    <th class="description" [pSortableColumn]="'dateOfBirth'"  width="160px">Date of Birth
                      <p-sortIcon [field]="'dateOfBirth'"></p-sortIcon>
                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-benefitsDependent>
                <tr >
                    <td class="description" *ngIf="!readOnly">
                      <span class="pointer mr-2" (click)="editBenefitsDependent(benefitsDependent)">
                        <i title="Edit Dependent" class="fas fa-edit"></i>
                      </span>
                      <span class="pointer mr-2" (click)="deleteBenefitsDependent(benefitsDependent)">
                        <i title="Delete Dependent" class="fas fa-trash"></i>
                      </span>
                    </td>
                    <td class="description">
                        <span class="ui-column-title">Name</span>
                      {{benefitsDependent.firstName }} {{benefitsDependent.lastName }}
                    </td>

                    <td class="description">
                        <span class="ui-column-title">Relationship</span>
                      {{benefitsDependent.relationship }}
                    </td>

                    <td class="description" >
                      <span class="ui-column-title">Date of Birth</span>
                    {{getAsDate(benefitsDependent.dateOfBirth) | date:'shortDate'}}
                  </td>
                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-dialog [header]="(benefitsDependentInfo.id?'Edit':'Add')+ ' Dependent'" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openDependentInfo">

            <form class="" [formGroup]="form" *ngIf="form && benefitsDependentInfo">
              <div class="row m-0">
                <div class="col-4 d-flex align-items-center">
                  <b>Legal First Name</b>
                </div>
                <div class="col-8 ">
                    <input id="firstNameInput" type="text" class="form-control mt-2" formControlName="firstName"/>
                    <app-validation-message [form]="form" [path]="'firstName'" [name]="'firstName'" [label]="'First Name'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Legal Middle Name</b>
                </div>
                <div class="col-8 ">
                    <input id="middleNameInput" type="text" class="form-control mt-2" formControlName="middleName"/>
                    <app-validation-message [form]="form" [path]="'middleName'" [name]="'middleName'" [label]="'Middle Name'"> </app-validation-message>
                </div>


                <div class="col-4 d-flex align-items-center">
                  <b>Legal Last Name</b>
                </div>
                <div class="col-8 ">
                    <input id="lastNameInput" type="text" class="form-control mt-2" formControlName="lastName"/>
                    <app-validation-message [form]="form" [path]="'lastName'" [name]="'lastName'" [label]="'Last Name'"> </app-validation-message>
                </div>


                <div class="col-4 d-flex align-items-center">
                  <b>Suffix</b>
                </div>
                <div class="col-8 ">
                  <p-dropdown styleClass="form-control mt-2 w-100" [options]="suffixOptions" placeholder="Select type..."  formControlName="suffixName"></p-dropdown>
                    <app-validation-message [form]="form" [path]="'suffixName'" [name]="'suffixName'" [label]="'Suffix'"> </app-validation-message>
                </div>


                <div class="col-4 d-flex align-items-center">
                  <b>Gender</b>
                </div>
                <div class="col-8 ">
                  <p-dropdown styleClass="form-control mt-2 w-100" [options]="genderOptions" placeholder="Select type..."  formControlName="gender"></p-dropdown>
                    <app-validation-message [form]="form" [path]="'gender'" [name]="'gender'" [label]="'Gender'"> </app-validation-message>
                </div>


                <div class="col-4 d-flex align-items-center">
                  <b>Relationship</b>
                </div>
                <div class="col-8 ">
                  <p-dropdown styleClass="form-control mt-2 w-100" [options]="relationshipOptions" placeholder="Select type..."  formControlName="relationship"></p-dropdown>
                    <app-validation-message [form]="form" [path]="'relationship'" [name]="'relationship'" [label]="'Relationship'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Date of Birth</b>
                </div>
                <div class="col-8 ">

                  <p-calendar dateMask
                  inputStyleClass="form-control mt-2"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  styleClass="w-100"
                  panelStyleClass="calendarPanel"
                  appendTo="body"
                  [hourFormat]="12"
                  [yearRange]="'1900:2100'"
                  [showTime]="false"
                  placeholder="Select date..."
                  formControlName="dateOfBirth"
                  dataType="string"
                  ></p-calendar>
                  <app-validation-message [form]="form" [path]="'dateOfBirth'" [name]="'dateOfBirth'" [label]="'Date of Birth'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>SSN</b>
                </div>
                <div class="col-8 ">
                    <input id="ssnInput" type="text" class="form-control mt-2" formControlName="ssn"/>
                    <app-validation-message [form]="form" [path]="'ssn'" [name]="'ssn'" [label]="'SSN'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Fulltime College Student</b>
                </div>
                <div class="col-8">
                  <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isFullTimeCollegeStudent"></p-checkbox>
                </div>


                <ng-container *ngIf="form.get('isFullTimeCollegeStudent').value">
                  <div class="col-4 d-flex align-items-center">
                    <b>College Start Date</b>
                  </div>
                  <div class="col-8 ">

                    <p-calendar dateMask
                    inputStyleClass="form-control mt-2"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1920:2100'"
                    [showTime]="false"
                    placeholder="Select date..."
                    formControlName="collegeStartDate"
                    dataType="string"
                    ></p-calendar>
                    <app-validation-message [form]="form" [path]="'collegeStartDate'" [name]="'collegeStartDate'" [label]="'College Start Date'"> </app-validation-message>
                  </div>

                  <div class="col-4 d-flex align-items-center">
                    <b>Expected Graduation Date</b>
                  </div>
                  <div class="col-8 ">

                    <p-calendar dateMask
                    inputStyleClass="form-control mt-2"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1920:2100'"
                    [showTime]="false"
                    placeholder="Select date..."
                    formControlName="expectedGraduationDate"
                    dataType="string"
                    ></p-calendar>
                    <app-validation-message [form]="form" [path]="'expectedGraduationDate'" [name]="'expectedGraduationDate'" [label]="'Expected Graduation Date'"> </app-validation-message>
                  </div>

                </ng-container>


                <div class="col-4 d-flex align-items-center">
                  <b>Disabled</b>
                </div>
                <div class="col-8">
                  <p-checkbox styleClass="mt-2" [binary]="true" formControlName="disabled"></p-checkbox>
                </div>

              <ng-container *ngIf="form.get('disabled').value">
                <div class="col-4 d-flex align-items-center">
                  <b>Disabled Date</b>
                </div>
                <div class="col-8 ">

                  <p-calendar
                  inputStyleClass="form-control mt-2"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  styleClass="w-100"
                  panelStyleClass="calendarPanel"
                  appendTo="body"
                  [hourFormat]="12"
                  [yearRange]="'1920:2100'"
                  [showTime]="false"
                  placeholder="Select date..."
                  formControlName="disabledDate"
                  dataType="string"
                  ></p-calendar>
                  <app-validation-message [form]="form" [path]="'disabledDate'" [name]="'disabledDate'" [label]="'Disabled Date'"> </app-validation-message>
                </div>

              </ng-container>


                <div class="col-4 d-flex align-items-center">
                  <b>Tobacco User</b>
                </div>
                <div class="col-8">
                  <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isTobbacoUser"></p-checkbox>
                </div>


                <div class="col-4 d-flex align-items-center">
                  <b>Same Address as Employee?</b>
                </div>
                <div class="col-8">
                  <p-checkbox styleClass="mt-2" [binary]="true" formControlName="isAddressSame"></p-checkbox>
                </div>

              <ng-container *ngIf="!form.get('isAddressSame').value">

                <div class="col-4 d-flex align-items-center">
                  <b>Address</b>
                </div>
                <div class="col-8 ">
                    <input id="addressInput" type="text" class="form-control mt-2" formControlName="address"/>
                    <app-validation-message [form]="form" [path]="'address'" [name]="'address'" [label]="'Address'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Address Extra</b>
                </div>
                <div class="col-8 ">
                    <input id="addressExtraInput" type="text" class="form-control mt-2" formControlName="addressExtra"/>
                    <app-validation-message [form]="form" [path]="'addressExtra'" [name]="'addressExtra'" [label]="'Address Extra'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>City</b>
                </div>
                <div class="col-8 ">
                    <input id="cityInput" type="text" class="form-control mt-2" formControlName="city"/>
                    <app-validation-message [form]="form" [path]="'city'" [name]="'city'" [label]="'City'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Province</b>
                </div>
                <div class="col-8 ">
                    <input id="provinceInput" type="text" class="form-control mt-2" formControlName="province"/>
                    <app-validation-message [form]="form" [path]="'province'" [name]="'province'" [label]="'Province'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>County</b>
                </div>
                <div class="col-8 ">
                    <input id="countyInput" type="text" class="form-control mt-2" formControlName="county"/>
                    <app-validation-message [form]="form" [path]="'county'" [name]="'county'" [label]="'County'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>State</b>
                </div>
                <div class="col-8 ">
                    <input id="stateInput" type="text" class="form-control mt-2" formControlName="state"/>
                    <app-validation-message [form]="form" [path]="'state'" [name]="'state'" [label]="'State'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Country</b>
                </div>
                <div class="col-8 ">
                    <input id="countryInput" type="text" class="form-control mt-2" formControlName="country"/>
                    <app-validation-message [form]="form" [path]="'country'" [name]="'country'" [label]="'Country'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Zip Code</b>
                </div>
                <div class="col-8 ">
                    <input id="zipCodeInput" type="text" class="form-control mt-2" formControlName="zipCode"/>
                    <app-validation-message [form]="form" [path]="'zipCode'" [name]="'zipCode'" [label]="'Zip Code'"> </app-validation-message>
                </div>

              </ng-container>

              </div>

              <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid" (click)="save()">Save</button>
            </form>
</p-dialog>
